<template>
    <div class="row mt-3">
        <div class="col-12 col-md-8 col-lg-9">
            <div class="day-calendar-appointment">
                <FullCalendar
                    ref="fullCalendar"
                    defaultView="resourceTimeGridDay"
                    :plugins="timeGridPlugins"
                    :schedulerLicenseKey="'GPL-My-Project-Is-Open-Source'"
                    :resources="resources"
                    :slotDuration="'00:05'"
                    :minTime="'07:00:00'"
                    :events="events"
                    :event-render="eventRender"
                    :eventTimeFormat="eventTimeFormat"
                    :slotLabelFormat="slotLabelFormat"
                    :nowIndicator="nowIndicator"
                    :eventOrder="'displayOrder'"
                    :header="false"
                    :progressiveEventRendering="true"
                ></FullCalendar>
            </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3">
            <side-panel @clickToggle="toggle" ref="notif"></side-panel>
        </div>
        <ModalInternal v-if="showModalInternal"></ModalInternal>
        <modal-reservasi v-if="showModalReservasi"></modal-reservasi>
        <modal-whatsapp :id-appointment="idAppointmentSelect" v-if="showModalWA"></modal-whatsapp>
        <modal-waiting v-if="showModalWaiting"></modal-waiting>
        <!-- <modalBlock
            v-if="showModalBlock"
        ></modalBlock> -->
        <modal-help
            v-if="showModalHelp"
        ></modal-help>
        <Modal-Temp-Wa
         v-if="showModalTempWa"
        ></Modal-Temp-Wa>
         <modal-payment-link
         v-if="showModalTelekonsul"
        ></modal-payment-link>
        <modal-mrreg
            v-if="showModalMrReg"
            :modal-data="selectedEvent.reservasi"
        ></modal-mrreg>
        <modal-history-reservasi
            v-if="showModalHistory"
        ></modal-history-reservasi>
        <ModalPrinter
            v-if="showModalPrinter"
            :payload="payloadPrint"
        ></ModalPrinter>
    </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { mapState, mapMutations, mapActions } from "vuex";
import CalendarEvent from "./_components/CalendarEvent";
import SidePanel from "./_components/SidePanel";
import LoadSpinner from "./../../_general/LoadSpinner";
import QueueDevice from "../../../model/queue-device-model";

// funtion print di bawah belum kepegang gan
import qz from "qz-tray";
const { RSAKey, KJUR, KEYUTIL, stob64, hextorstr } = require("jsrsasign");
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
export default {
    components: {
        FullCalendar,
        SidePanel,
        ModalReservasi: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-reservasi-dokter" */ "./../ModalReservasi/ModalReservasi.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        ModalMrreg: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-reservation-mr-reg" */ "../../reservations/_components/ModalMrReg")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        ModalTempWa: () => ({
            component: new Promise(function (resolve, reject) {
                setTimeout(function () {
                resolve(
                    import(
                    /* webpackChunkName: "modal-reservation-mr-reg" */ "../../doctor-appointments/ModalReservasi/_components/ModalTempWa"
                    )
                );
                }, 1000);
        }),
        loading: LoadSpinner
        }),
        ModalPaymentLink: () => ({
            component: new Promise(function (resolve, reject) {
                setTimeout(function () {
                resolve(
                    import(
                    /* webpackChunkName: "modal-reservation-mr-reg" */ "../../doctor-appointments/ModalReservasi/_components/ModalPaymentLink"
                    )
                );
                }, 1000);
        }),
        loading: LoadSpinner
        }),
        ModalWaiting: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-waiting-list" */ "./../ModalWaiting/ModalWaiting.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        ModalInternal: () => ({
            component: 
            new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-internal-event" */ "../ModalInternalEvent/ModalInternalEvent.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        ModalBlock: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-block" */ "../modalBlock/modalBlock")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        ModalHelp: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-help" */ "../ModalHelp/ModalHelp")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        ModalHistoryReservasi: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(() => {
                    resolve(
                        import(/* webpackChunkName: "modal-history-reservasi" */ "./_components/ModalHistory.vue")
                    );
                }, 500);
            })
        }),
        ModalPrinter: () => ({
                component: new Promise(function (resolve, reject) {
                    setTimeout(() => {
                        resolve(import("../../_select/SelectPrinter.vue"))
                    }, 1000)
                })
            }),
        ModalWhatsapp: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(() => {
                    resolve(
                        import(/* webpackChunkName: "modal-history-reservasi" */ "./_components/ModalWhatsapp.vue")
                    );
                }, 500);
            })
        })
    },
    data() {
        var vx = this;
        return {
            slotData:0,
            isStillLoading : false,
            test: '',
            locationEvents: 0,
            timeOutBatch: '',
            maximalBatch: 40,
            datenow: new Date().getTime(),
            timeGridPlugins: [resourceTimeGridPlugin],
            eventTimeFormat: {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            },
            slotLabelFormat: {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            },
            eventRender: function(info) {
                const data = JSON.parse(info.event.title);
                let jumlah = data.waitingCount;
                let calendarEvent = Vue.extend(CalendarEvent);
                let instance = new calendarEvent({
                    propsData: {
                        xVue: vx.$store,
                        data: data,
                        jumlahWaiting: jumlah,
                        start: moment(info.event.start).format(
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                        end: moment(info.event.end).format(
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                        hexColor: data.hexColor
                    }
                });
                instance.$mount(info.el.querySelector(".fc-content"));
            },
            showModalHistoryReservasi: false,
            payloadPrint: {
                    printD: false,
                    printSelected: null
                }
        };
    },
    watch: {
        resources(value) {
            var width = 100;
            if (value.length > 2) {
                var widthContainer = $(".day-calendar-appointment").width();
                var widthOneRecource = Math.floor(widthContainer / 2);
                var widthAllResource = widthOneRecource * value.length;
                width = (widthAllResource * 100) / widthContainer ;
            }
        
            $(".day-calendar-appointment .fc-view > table").css(
                "width",
                `${width}%`
            );
        }
    },
    computed: {
        idAppointmentSelect () {
            return this.$store.state.DayCalendarStore.idReservasi
        },
        ...mapState("DayCalendarStore", {
            resources: state => state.resources,
            events: state => state.events,
            showModalWaiting: state => state.showModalWaiting,
            showModalInternal: state => state.showModalInternal,
            listWaiting: state => state.listWaiting,
            showModalBlock: state => state.showModalBlock,
            showModalWA: state => state.showModalWA,
            showModalHelp: state => state.showModalHelp,
            showModalTempWa: state => state.showModalTempWa,
            showModalTelekonsul: state => state.showModalTelekonsul,
            showModalMrReg: state => state.showModalMrReg,
            selectedEvent: state => state.selectedEvent,
            showModalHistory: state => state.showModalHistory,
            isLoadFullCalendar: state => state.isLoadFullCalendar,
            isCompleteFullCalendar: state => state.isCompleteFullCalendar
        }),
        ...mapState("CallerStore", {
            queueDevice: state => state.queueDevice,
            userNotification: state => state.userNotification,
            showModalPrinter: state => state.showModalPrinter,
            defaultPrinter: state => state.defaultPrinter,
            isLoading: state => state.isLoading
        }),
        ...mapState("DoctorAppointmentStore", {
            dokter: state => state.dokter,
            poli: state => state.poli,
            calendarApi: state => state.calendarApi,
            showModalReservasi: state => state.showModalReservasi
        }),
        nowIndicator() {
            let d = new Date();
            return `${d.getHours()}:${d.getMinutes()}`;
        }
    },
    methods: {
        ...mapMutations({
            setCalendarApi: "DoctorAppointmentStore/SET_CALENDAR_API",
            setSelectedEvent: "DayCalendarStore/SET_SELECTED_EVENT",
            setDokter: "DoctorAppointmentStore/SET_DOKTER",
            setPoli: "DoctorAppointmentStore/SET_POLI",
            setPermissionWhatsapp: "DoctorAppointmentStore/SET_PERMISSION_WHATSAPP",
            // PRINTER
            setDefaultPrinter: "CallerStore/SET_DEFAULT_PRINTER",
            setPrintingOption: "CallerStore/SET_PRINTING_OPTION"
        }),
        ...mapActions({
            getEvent: "DayCalendarStore/GET_EVENT",
            RELOAD_CALENDAR: "DoctorAppointmentStore/RELOAD_CALENDAR",
            saveQueueDevice: "DoctorAppointmentStore/SAVE_QUEUE_DEVICE",
            getSlotEvents: 'DayCalendarStore/GET_SLOT_EVENT'
        }),
        settingQueueDevice() {
            setTimeout(() => {
                const device = { ...this.queueDevice };
                device.queueUnits = [];
                device.dokters =
                    this.dokter != null
                        ? [
                              {
                                  id: `${this.dokter.id}`
                              }
                          ]
                        : [];
                device.notificationId = this.userNotification
                    ? this.userNotification.notificationId
                    : null;
                this.saveQueueDevice(device);
            }, 10);
        },
        toggle() {
            this.$emit("clickToggle", true);
        },
        async initPrinter() {
            let vx = this;
            // ini daftarinnya qz sertifikat biar prompt hanya untuk pertama kali
            qz.security.setCertificatePromise(function(resolve, reject) {
                resolve(
                    "-----BEGIN CERTIFICATE-----\n" +
                        "MIID2DCCAsCgAwIBAgIJALt852c596t1MA0GCSqGSIb3DQEBCwUAMIGAMQswCQYDVQQGEwJJRDESMBAGA1UECAwJSW5kb25lc2lhMRAwDgYDVQQHDAdKYWthcnRhMR8wHQYDVQQKDBZQVCBNYW5kYXlhIFNlaGF0IFV0YW1hMQwwCgYDVQQLDANNU1UxHDAaBgNVBAMMEyoubWFuZGF5YWhlYWx0aC5jb20wIBcNMjAwODExMTAzNDE0WhgPMjA1MjAyMDQxMDM0MTRaMIGAMQswCQYDVQQGEwJJRDESMBAGA1UECAwJSW5kb25lc2lhMRAwDgYDVQQHDAdKYWthcnRhMR8wHQYDVQQKDBZQVCBNYW5kYXlhIFNlaGF0IFV0YW1hMQwwCgYDVQQLDANNU1UxHDAaBgNVBAMMEyoubWFuZGF5YWhlYWx0aC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQC7qAv8vXSzThYEYEQuMFhX2X6WPbDk9sW+vKxNgdhOy73B7IWg5vem0lOdiXzqdWauAR45F3Wti5g1PcECJL0mxIzfki9T+9wNgvsGz3r++01twOtVxv8teIEQAes4m+J/nULDWfivam2VyAWn2tpo7w56NKtHnMfeZjJ22lXjtRw6nqCxsLltk+mUwMX7X0KMOn5dzSv/99vr04UbiPNi/a1XKjPgNGS59bWeEJAy1OA5iz2/DzAHlOJn4rHliIR60eHRLGhl1rM4zni3/4bHHomL1QK1JWyHE8RndRy+equGdgqN7tbkiyLPSGyjaZ6yTP5dMbPEJnRQPOEQbN13AgMBAAGjUTBPME0GA1UdEQRGMESCFmRlbW8ubWFuZGF5YWhlYWx0aC5jb22CGmludGVybmFsLm1hbmRheWFoZWFsdGguY29tgg4qLnF6dHJheS5sb2NhbDANBgkqhkiG9w0BAQsFAAOCAQEAZmjJB1RNUSk+A2xIvtLUonVsRbI8BpXh49O5Qbj5Pz5g/0mx8qq83pRq3etORBC85X3PKRU2PFmPOCiVuN8AZojXFUunDSx4pOVWypfmgirRk8l91TeCVXaAoeKsgYxg8xH7u02gIg1O5E0XZcy4KcoUCvDkqYq55IV07ZcFLFqhAxZTyH15IiJ/p9dgSgM3NWSEGp7y8MPXXtl3ODwpLlxJiawzfgwl4evjSZSst5ASOAY9PdjYuh4BKtRtRv8wmyYCHgw90ZE6aNOxhR3DDK5NxeoJcOVDNl3SZ3CZesx57tU+oGuNLJd/AiTZE8PwDljjoIQXLKx/iVi+A9xnDQ==\n" +
                        "-----END CERTIFICATE-----"
                );
            });

            var privateKey =
                "-----BEGIN PRIVATE KEY-----\n" +
                "MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC7qAv8vXSzThYEYEQuMFhX2X6WPbDk9sW+vKxNgdhOy73B7IWg5vem0lOdiXzqdWauAR45F3Wti5g1PcECJL0mxIzfki9T+9wNgvsGz3r++01twOtVxv8teIEQAes4m+J/nULDWfivam2VyAWn2tpo7w56NKtHnMfeZjJ22lXjtRw6nqCxsLltk+mUwMX7X0KMOn5dzSv/99vr04UbiPNi/a1XKjPgNGS59bWeEJAy1OA5iz2/DzAHlOJn4rHliIR60eHRLGhl1rM4zni3/4bHHomL1QK1JWyHE8RndRy+equGdgqN7tbkiyLPSGyjaZ6yTP5dMbPEJnRQPOEQbN13AgMBAAECggEAEHG+cyxrAJd0KHgDIYUNCM2/YQ0Q5k+Vrow8ESKywNzryQWgBCSZSR4fQ6vbrXQSRmdErb4Xitizwt6hfj2X8cDBk4ns6dAlTW3P753Y6mU9OMPyG3cvv11S+gMhCLnoVESEqDfHKiAKcFix3i64ev+P5jedVs4vWZbdDzs80ZJTuHSQUZNp7/i61Cyyv6d8J+2jqntJvid9eaZoDzNrfpwlKp0GCoj0x3YqAoT/476eyCsZdaGxjac5sp+wjqn7imFeCNJ0t78JoyKNjbg9nEkxGpRHqLWDpAExo7FpNN39jCSb5tzFYAQg4hz2krRpsrDAoCm58jr524zhmH0ZkQKBgQD1in/yEjBoriku4DopYcEscYy9Bp/mWFqmaombxQJ3AvWbpfjGn/pIgy6Xt262dGbHT4RjNh6K0IaqOCho18wBAHzcTi+NyBGpy3EJ9Pm7AassURBVHv6yAYNS4rbSPWQuwpDGDgziH3WhXUdiIuI2zs4x9edt/VPIk2XnlIqvSQKBgQDDplhUN3kG0tKC2w+CD8cxoj5VU9XwDJGAhSwO+ka2Dh3r2573JObq2Yb+2HTmU2Feo/VlfYWQyKaQivUeHHeLnnzDzFaETuGMYkHPNk4sNdXng7ja1OgVbwQiT9+6PnAceZ/+lVaZ2CPdFTQjnPgTdQQRDeZIoaZnKAMZKTVmvwKBgQDvLPAgfnNFJJDdJnHBKYWfoyPyZOeQ2vPCMvjEEQ2rm+heqzc/U0nbJF1YT8e6VnxfHSfZ9DegBCuI15cVOU9wkmcNueM8emwqNw9vIX+5F5EDZBtCryRthGfFby2icUaK/d9D7R/Di91rR49nGo+7z4quw9ehHihksp+LCvhkcQKBgA/JVEc3UqSiTioQ0ueDrmbq2Gy6HwEHgpYEKNHsj9gQu4lUZzc52wgIFURq4sPS5oBkamCOCEDub8Ip9C1BzsONPjPuQOzcEIbrRrQZhaU7ks7jaf7+Ptu74XGDUGIOt60UgzxfgGVZc0tbcqGNANdREcrufC5QHMUmDVnyYUSxAoGBAKkO4oHHkNeIME4IKNbKytvqVlDQojA6ca+0MqB06W2R3Y3IqW6d8xGUr6UwDFqxLTcou5sGFe1WQ2XEMpSRa0XwPio/5DUr2RIxXyj1ijQ7HIT7+COKaeGFekBLcrMGx9vrNptMOmPyrGki8usjJ4QyNQ7HFwLjad/0jrIqspxa\n" +
                "-----END PRIVATE KEY-----";
            function strip(key) {
                if (key.indexOf("-----") !== -1) {
                    return key.split("-----")[2].replace(/\r?\n|\r/g, "");
                }
            }

            qz.security.setSignaturePromise(function(toSign) {
                return function(resolve, reject) {
                    try {
                        var pk = KEYUTIL.getKey(privateKey);
                        var sig = new KJUR.crypto.Signature({
                            alg: "SHA1withRSA"
                        });
                        sig.init(pk);
                        sig.updateString(toSign);
                        var hex = sig.sign();
                        resolve(stob64(hextorstr(hex)));
                    } catch (err) {
                        console.error(err);
                        reject(err);
                    }
                };
            });

            let host = "localhost";
            if (localStorage.selectedServer) {
                host = localStorage.selectedServer;
            }
            const options = {
                host: `${host}`,
                port: {
                    secure: [8181]
                },
                usingSecure: true
            };

            if (!qz.websocket.isActive()) {
                await qz.websocket
                    .connect(options)
                    .then(() => {
                        if (
                            !localStorage.printingOption ||
                            localStorage.printingOption !== "DIALOG"
                        ) {
                            vx.setPrintingOption("SERVER");
                        } else {
                            localStorage.printingOption = "DIALOG";
                        }
                        return qz.printers.find();
                    })
                    .then(data => {
                        if (data) {
                            localStorage.printerList = JSON.stringify(data);
                        } else {
                            toastr.error("Can't find Printer");
                        }
                    })
                    .catch(_ => {
                        vx.setPrintingOption("DIALOG");
                    });
            }
        },
        notif() {
            this.$refs.notif.notif();
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
    },
    mounted() {
        this.setCalendarApi(this.$refs.fullCalendar.getApi());
        this.setPermissionWhatsapp(this.checkPermission('Booking.Whatsapp.Send.Active'))
        this.setSelectedEvent(null);
        if (localStorage.defaultPrinter) {
            this.setDefaultPrinter(localStorage.defaultPrinter);
        }
        if (localStorage.printingOption) {
            this.setPrintingOption(localStorage.printingOption);
        }
        this.initPrinter();
    },
    created() {
        // this.settingQueueDevice();
    }
};
</script>

<style lang="scss" scoped></style>
